.landing-banner {
    background: url("/images/intro-bg.png") center no-repeat;
    background-size: cover;
    padding: 10.2em 0;
    --landing-banner-blue-color: #007bff;
}

@media (max-width: 768px) {
    .landing-banner {
        padding: 6.2em 0 1em 0;
    }
    #intro h2, #intro h2+div {
        text-align: center;
    }
    #intro h2 {
        font-size: 2em !important;
    }
    #intro img {
        margin: 0 auto 1.5em auto;
    }
}

#intro h2 {
    margin-bottom: 40px;
    font-size: 3rem;
}

#intro h2 span {
    color: #74b5fc;
    text-decoration: underline;
}

.intro-btn {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 1px;
    padding: 10px 32px;
    border-radius: 50px;
    transition: 0.5s;
    margin: 0 20px 20px 0;
}

.btn-services:hover, .btn-getstarted {
    background: #007bff;
    border: 2px solid #007bff;
}

.btn-getstarted:hover {
    border: 2px solid #fff;
    background: none;
}

.btn-services {
    border: 2px solid #fff;
}