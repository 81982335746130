.footer {
    color: #eee;
    font-size: 14px;
}

/* Footer Top */

.footer-top {
    background: #004a99;
    padding: 60px 0;
}

.footer-top h4 {
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 10px;
}

.footer-top ul li a {
    color: white;
    transition: all .3s ease-in-out;
}

.footer-top ul li:hover a {
    color: #74b5fc;
}

/* Footer Info */

.footer-top .footer-info h3 {
    font-size: 34px;
    margin: 0 0 20px 0;
    padding: 2px 0 2px 0;
    line-height: 1;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.footer-top .footer-info p {
    font-size: 13px;
    line-height: 24px;
    color: #ecf5ff;
}

/* Footer Top: Usefull Links */

.footer-top .footer-links ul {
    list-style: none;
}

.footer-top .footer-links li {
    padding: 8px 0;
}

/* Footer Top : Address Social Links */

.footer-top .footer-address {
    line-height: 26px;
}

.footer-top .social-links a {
    font-size: 18px;
    display: inline-block;
    background: #007bff;
    color: #fff;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 50%;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;
}

.footer-top .social-links a:hover {
    background: #0067d5;
}

/* Copyright section */

.copyright-band {
    background: #00428a;
    padding: 30px;
    text-align: center;
}