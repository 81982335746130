#whyus {
    background-color: #004a99;
    padding: 60px 0;
}

#whyus .card {
    background: #00458f;
    border-color: #00458f;
    text-align: center;
    border-radius: 10px;
    color: #d8eafe;
    height: 100%;
    transition: 0.3s ease-in-out;
}

#whyus .card:hover {
    background: #003b7a;
    border-color: #003b7a;
}

#whyus .card p {
    font-size: .9rem;
}

#whyus .card i {
    font-size: 3em;
    padding-top: .7em;
    color: #bfddfe;
}

/* Section Title Color Adjustment */
#whyus .section-header h3, #whyus .section-header p {
    color: white;
}