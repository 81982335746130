body {
  margin: 0;
  /* font-family: 'Nunito Sans', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Open Sans', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Custom Variables */
  /* Colors */
  --ks-nav-brand-color: #007bff;
  --ks-mobile-bg-color: #1545b3;
  /* fonts */
  --font-montserrat: "Montserrat", sans-serif;
  --font-open-sans : "Open Sans", sans-serif;
  /* Custom styles */
  background: #fff;
  color: #444;
  line-height: 1.5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: var(--font-montserrat);
}

a {
  outline: 0 !important;
}
a:hover {
  text-decoration: none;
}

/* fonts */

.font-montserrat {
  font-family: var(--font-montserrat);
}

.font-open-sans {
  font-family: var(--font-open-sans);
}