#about-kodestack img {
    width: 100%
}
#about-kodestack {
    padding: 60px 0;
}

.img-container {
    margin: 20px 0;
}
#about-kodestack .content p {
    line-height: 26px;
    margin: 30px 0;
}



