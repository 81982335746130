.section-header {
    color: #283d50;
}

.section-header h3 {
    font-size: 2.2rem;
    font-weight: 500;
    margin-bottom: 20px;
}

.section-header p {
    font-size: .9rem;
    line-height: 1.7em;
    padding-bottom: 4rem;
}