#contact-us {
  padding: 60px 0;
}

.gmap {
  border: 0;
  width: 100%;
  height: 100%;
}

.content {
  margin: unset;
}

#contact-us .info {
  color: #283d50;
}

#contact-us .info i {
  font-size: 32px;
  color: #007bff;
  float: left;
  line-height: 1.2;
}

#contact-us .info p {
  padding: 0 0 10px 36px;
  line-height: 28px;
  font-size: 14px;
}

#contact-us .form input, #contact-us .form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
}

#contact-us .form .submit-btn {
  background: #007bff;
  border: 0;
  border-radius: 20px;
  padding: 8px 30px;
  color: #fff;
  transition: 0.3s;
}

#contact-us .form .submit-btn:hover {
  background: #0067d5;
  cursor: pointer;
}