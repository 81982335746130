.ks-header {
    box-shadow: 0 0 30px rgba(127, 137, 161, 0.3);
    height: 64px;
}

.ks-header .navbar-brand {
    color: var(--ks-nav-brand-color);
    font-weight: 600;
    font-size: 2rem;
}

.ks-header .navbar-brand-logo {
    height: 3rem;
}

.ks-header .navbar-nav .nav-link {
    color: inherit;
    font-size: 14px;
    font-weight: 500;
    font-family: inherit;
}

/* Mobile Menu Toggler Styles */

.ks-header .navbar-toggler, .ks-header .navbar-toggler:focus {
    background-color: transparent !important;
    color: var(--ks-nav-brand-color) !important;
    border: none !important;
    box-shadow: none !important;
    z-index: 3;
}

.ks-header .navbar-toggler.open {
    color: white !important;
}

/* For Laptops and larger screens */

@media screen and (min-width: 768px) {
    .ks-header .nav-item .nav-link {
        color: #004289;
    }
    /* Active and Hovered Nav Item */
    .ks-header .nav-item .nav-link.active, .ks-header .nav-item .nav-link:hover, .ks-header .nav-item.active .nav-link {
        color: #007bff;
    }
    /* Navbar shrinking on scrolling */
    .ks-header {
        height: 80px; 
        transition: 0.5s;
    }
    .ks-header.ks-scrolled {
        height: 64px;
    }

    .ks-header .navbar-brand-logo {
        height: 4rem;
        transition: all .3s ease-in-out;
    }

    .ks-header.ks-scrolled .navbar-brand-logo {
        height: 3.5rem;
    }
}

@media screen and (max-width: 768px) {
    .ks-header .collapse.navbar-collapse {
        display: flex;
    }
    .ks-header .navbar-nav-wraper {
        position: fixed;
        height: 100vh;
        width: 100vw;
        left: 0;
        top: 0;
        background: var(--ks-mobile-bg-color);
        clip-path: circle(100px at 90% -20%);
        -webkit-clip-path: circle(100px at 90% -20%);
        transition: all 900ms ease-out;
        z-index: 2;
    }
    .ks-header .navbar-nav-wraper.open {
        clip-path: circle(1000px at 90% -20%);
        -webkit-clip-path: circle(1000px at 90% -20%);
        pointer-events: all;
    }
}