#kodestack-services {
    background: #ecf5ff;
    padding: 60px 0;
}

#kodestack-services .service-card {
    padding: 30px;
    overflow: hidden;
    border-radius: 10px;
    margin: 0 10px 40px 10px;
    box-shadow: 0 10px 29px 0 rgba(68, 88, 144, 0.1);
    transition: all 0.3s ease-in-out;
}

#kodestack-services .service-card:hover {
    transform: translateY(-5px);
}

#kodestack-services .service-card h4 {
    color: black;
    font-size: 1.2rem;
}

#kodestack-services .service-card:hover h4 {
    color: #007bff;
    /* cursor: pointer; */
}

#kodestack-services .service-card .icon {
    font-size: 64px;
    line-height: 2;
}

#kodestack-services .service-card p {
    font-size: 14px;
    margin-left: 10px;
    min-height: 63px;
}